var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.topMenu && _vm.topMenu.length)?_c('BaseTouchScroll',[_c('ul',{staticClass:"flex items-center min-h-10 font-bold text-xs tracking-wide whitespace-nowrap"},_vm._l((_vm.topMenu),function(menu){return _c('li',{key:menu.key,staticClass:"first-of-type:text-white last-of-type:after:content-none after:content-[' '] after:inline-block after:mx-2.5 after:opacity-20"},[_c(menu.alternateUrl ? 'a' : 'NuxtLink',_vm._b({tag:"component",staticClass:"hover:underline"},'component',
          menu.alternateUrl
            ? {
                href: menu.alternateUrl,
              }
            : {
                to: _vm.localePath({
                  name: 'all',
                  params: { pathMatch: menu.url },
                }),
              }
        ,false),[_vm._v("\n         "+_vm._s(menu.name)+"\n      ")])],1)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }