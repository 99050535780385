import { render, staticRenderFns } from "./CartSummary.vue?vue&type=template&id=174aca18&"
import script from "./CartSummary.vue?vue&type=script&lang=ts&"
export * from "./CartSummary.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeaderCartLine: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/20/a/NuxtApp/components/app/header/CartLine.vue').default,BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/20/a/NuxtApp/components/base/Icon.vue').default,AppHeaderCartTotal: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/20/a/NuxtApp/components/app/header/CartTotal.vue').default,CartStockWarning: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/20/a/NuxtApp/components/cart/StockWarning.vue').default,CartSubmitButton: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/20/a/NuxtApp/components/cart/SubmitButton.vue').default})
