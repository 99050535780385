var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-panel bg-white max-h-50vh text-base shadow-lg overflow-auto rounded-2xl "},[(_vm.trimedKeyword)?[(_vm.loading)?_c('div',{staticClass:"px-3.5 py-2.5"},[_vm._v("\n      "+_vm._s(_vm.$t('common.text.loading'))+"\n    ")]):(_vm.suggestions)?_c('div',{staticClass:"flex-1 overflow-auto pt-3 px-3 md:px-6"},[(_vm.suggestions.brands)?_c('div',{staticClass:"border-b border-b-pfm-grey-400 last:border-b-0"},[_c('h4',{staticClass:"capitalize text-right font-body text-xs font-normal text-[#757575] px-3 py-1.5"},[_vm._v("\n          "+_vm._s(_vm.suggestions.brands.name)+"\n        ")]),_vm._v(" "),_c('ul',_vm._l((_vm.suggestions.brands.suggestions),function(brand,index){return _c('li',{key:("brand-" + index)},[_c('NuxtLink',{staticClass:"block w-full first-letter:uppercase md:hover:bg-[#f5f5f5] px-3 py-0.5",attrs:{"to":_vm.localePath(
                  brand.url
                    ? {
                        name: 'brand-lister-all',
                        params: { pathMatch: brand.url },
                      }
                    : { name: 'index' }
                )},nativeOn:{"click":function($event){_vm.onClickItem(
                      brand.name,
                      _vm.localePath({
                        name: 'brand-url',
                        params: { url: brand.url },
                      })
                    )}}},[_c('div',{staticClass:"truncate",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(brand.name))}})])],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.suggestions.aheadWords)?_c('div',{staticClass:"border-b border-b-pfm-grey-400 last:border-b-0"},[_c('h4',{staticClass:"capitalize text-right font-body text-xs font-normal text-[#757575] px-3 py-1.5"},[_vm._v("\n          "+_vm._s(_vm.suggestions.aheadWords.name)+"\n        ")]),_vm._v(" "),_c('ul',_vm._l((_vm.suggestions.aheadWords.suggestions),function(aheadWord,index){return _c('li',{key:("aheadWord-" + index)},[_c('NuxtLink',{staticClass:"block w-full first-letter:uppercase md:hover:bg-[#f5f5f5] px-3 py-0.5",attrs:{"to":_vm.localePath(
                  aheadWord.name
                    ? {
                        name: 'search-slug',
                        params: { slug: aheadWord.name },
                      }
                    : { name: 'index' }
                )},nativeOn:{"click":function($event){_vm.onClickItem(
                      aheadWord.name,
                      _vm.localePath({
                        name: 'aheadWord-url',
                        params: { url: aheadWord.url },
                      })
                    )}}},[_c('div',{staticClass:"truncate",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(aheadWord.name))}})])],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.suggestions.categories)?_c('div',{staticClass:"border-b border-b-pfm-grey-400 last:border-b-0"},[_c('h4',{staticClass:"capitalize text-right font-body text-xs font-normal text-[#757575] px-3 py-1.5"},[_vm._v("\n          "+_vm._s(_vm.suggestions.categories.name)+"\n        ")]),_vm._v(" "),_c('ul',_vm._l((_vm.suggestions.categories.suggestions),function(category,index){return _c('li',{key:("category-" + index)},[_c('NuxtLink',{staticClass:"block w-full first-letter:uppercase md:hover:bg-[#f5f5f5] px-3 py-0.5",attrs:{"to":_vm.localePath(
                  category.url
                    ? {
                        name: 'product-lister-all',
                        params: { pathMatch: _vm.getCategoryUrl(category) },
                      }
                    : { name: 'index' }
                )},nativeOn:{"click":function($event){_vm.onClickItem(
                      category.name,
                      _vm.localePath({
                        name: 'category-url',
                        params: { url: _vm.getCategoryUrl(category) },
                      })
                    )}}},[_c('div',{staticClass:"truncate",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(category.name))}})])],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.suggestions.products)?_c('div',{staticClass:"border-b border-b-pfm-grey-400 last:border-b-0"},[_c('h4',{staticClass:"capitalize text-right font-body text-xs font-normal text-[#757575] px-3 py-1.5"},[_vm._v("\n          "+_vm._s(_vm.suggestions.products.name)+"\n        ")]),_vm._v(" "),_c('ul',_vm._l((_vm.suggestions.products.suggestions),function(product,index){return _c('li',{key:("product-" + index)},[_c('NuxtLink',{staticClass:"block w-full first-letter:uppercase md:hover:bg-[#f5f5f5] px-3 py-0.5",attrs:{"to":_vm.localePath(
                  product.url
                    ? {
                        name: 'product-detail-slug',
                        params: { slug: product.url },
                      }
                    : { name: 'index' }
                )},nativeOn:{"click":function($event){_vm.onClickItem(
                      product.name,
                      _vm.localePath({
                        name: 'product-url',
                        params: { url: product.url },
                      })
                    )}}},[_c('div',{staticClass:"truncate",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(product.name))}})])],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.suggestions.blogs)?_c('div',{staticClass:"border-b border-b-pfm-grey-400 last:border-b-0"},[_c('h4',{staticClass:"capitalize text-right font-roboto text-xs font-normal text-[#757575] px-3 py-1.5"},[_vm._v("\n          "+_vm._s(_vm.suggestions.blogs.name)+"\n        ")]),_vm._v(" "),_c('ul',_vm._l((_vm.suggestions.blogs.suggestions),function(blog,index){return _c('li',{key:("blog-" + index)},[_c('NuxtLink',{staticClass:"block w-full first-letter:uppercase md:hover:bg-[#f5f5f5] px-3 py-0.5",attrs:{"to":_vm.localePath(
                  blog.url
                    ? {
                        name: 'blog-detail-slug',
                        params: { slug: blog.url },
                      }
                    : { name: 'index' }
                )},nativeOn:{"click":function($event){return _vm.onClickItem.apply(null, arguments)}}},[_c('div',{staticClass:"truncate",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(blog.name))}})])],1)}),0)]):_vm._e()]):_vm._e()]:(_vm.searchHistoryTerms.length)?_c('div',{staticClass:"flex-1 overflow-auto pt-4 mt-5 px-3 md:px-6",attrs:{"data-scroll-lock-scrollable":""}},[_c('p',{staticClass:"font-bold text-bm-passive-grey-dark px-3 py-2"},[_vm._v("\n      Je zocht eerder op\n    ")]),_vm._v(" "),_c('ul',{staticClass:"bg-white rounded"},_vm._l((_vm.searchHistoryTerms),function(history,index){return _c('li',{key:("history-" + index),staticClass:"flex justify-between items-center px-3 py-2"},[(history.name)?_c('NuxtLink',{staticClass:"flex-1 hover:underline mr-2",attrs:{"to":_vm.getUrlFromHistory(history)},nativeOn:{"click":function($event){return _vm.hideSearchPanel.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(history.name)+"\n        ")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"inline-flex justify-center items-center",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeSearchHistory(history)}}},[_c('BaseIcon',{staticClass:"text-bm-passive-grey-dark w-4 h-4",attrs:{"name":"pfm-close"}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v("close icon")])],1)],1)}),0)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }