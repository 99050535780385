var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between"},[_c('NuxtLink',{staticClass:"relative block w-20 h-20 mr-3.5",attrs:{"to":_vm.localePath(
        _vm.product.url
          ? {
              name: 'product-detail-slug',
              params: { slug: _vm.product.url },
            }
          : {
              name: 'index',
            }
      )}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.productImage),expression:"productImage"}],staticClass:"w-full h-full object-contain",attrs:{"width":"90","height":"90","alt":_vm.product.name}}),_vm._v(" "),(_vm.product.discounts && _vm.product.discounts.length)?_c('div',{staticClass:"absolute top-0 left-0 z-10 max-w-full"},[_c('ProductDiscountLabels',{attrs:{"size":"xs","gap":"space-y-0.5","discounts":_vm.product.discounts}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mr-4 flex-1 self-start"},[_c('h3',{staticClass:"text-md font-bold pt-2.5 line-clamp-3"},[_c('NuxtLink',{staticClass:"hover:underline",attrs:{"to":_vm.localePath(
            _vm.product.url
              ? {
                  name: 'product-detail-slug',
                  params: { slug: _vm.product.url },
                }
              : {
                  name: 'index',
                }
          )}},[_vm._v("\n        "+_vm._s(_vm.product.name)+"\n      ")])],1)]),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"mr-4 text-normal"},[(_vm.line.priceInclTax)?_c('i18n-n',{attrs:{"value":_vm.line.priceInclTax,"format":"currency"}}):_c('span',{staticClass:"uppercase"},[_vm._v(_vm._s(_vm.$t('text.free'))+"! ")])],1),_vm._v(" "),_c('div',{staticClass:"w-20 mr-2"},[_c('BaseInputNumber',{attrs:{"block":"","contronl-position":"right","value":_vm.line.quantity,"min":1,"max":_vm.maxQuantity || 1,"readonly":_vm.line.derived},on:{"change":function($event){return _vm.onChangeQuantity($event)}}})],1),_vm._v(" "),_c('BaseButton',{staticClass:"bg-pfm-grey-400 hover:bg-pfm-error",attrs:{"icon-button":"","icon":"pfm-trash","disabled":_vm.line.derived},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeFromCart(_vm.line.productId)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }